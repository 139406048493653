import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { path, prop } from 'ramda';
import Heading from '@greenberry/salal/components/Heading';
import HeroV4 from '@greenberry/salal/components/Hero/v4';
import Text from '@greenberry/salal/components/Text';
import Icon from '@greenberry/salal/components/Icon';
import Button from '@greenberry/salal/components/Button';
import Section from '@greenberry/salal/components/Section';
import MainWrapper from '@greenberry/salal/components/MainWrapper';
import ImageHighlightCard from '@greenberry/salal/components/ImageHighlightCard';
import RichText from '@greenberry/salal/components/RichText';

import Layout from '../../components/Layout';
import SEO from '../../components/SEO';

const Encyclopedia = () => {
  const { begrippenImage, encyImage, copy } = useStaticQuery(graphql`
    query getEncy {
      copy: datoCmsEncyclopediaCopy {
        seoMetaTags {
          tags
        }
        title
        intro
        conceptsTitle
        conceptsBody
        conceptsButtonLabel
        indicatorTitle
        indicatorBody
        indicatorButtonLabel
      }
      begrippenImage: file(name: { in: "begrippen" }) {
        childImageSharp {
          fluid(maxWidth: 525) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      encyImage: file(name: { in: "ency" }) {
        childImageSharp {
          fluid(maxWidth: 525) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO tags={path(['seoMetaTags', 'tags'], copy)} />
      <HeroV4
        content={
          <>
            <Heading
              component="h1"
              size="xxl"
              weight="semibold"
              appearance="secondary"
            >
              {prop('title', copy)}
            </Heading>
            <Text size="m" appearance="base">
              {prop('intro', copy)}
            </Text>
          </>
        }
        background="transparent"
        align="left"
      />
      <MainWrapper style={{ paddingTop: 0 }}>
        <Section size="2">
          <ImageHighlightCard
            node={<Img fluid={encyImage.childImageSharp.fluid} />}
            orientation="ltr"
          >
            <Heading
              appearance="secondary"
              component="h1"
              size="xl"
              weight="bold"
            >
              {prop('indicatorTitle', copy)}
            </Heading>
            <RichText>{prop('indicatorBody', copy)}</RichText>
            <br />
            <Link to="/encyclopedie/uitleg-indicatoren">
              <Button styling="solid">
                <span>{prop('indicatorButtonLabel', copy)}</span>
                <Icon type="arrowCircleRight" size="lg" />
              </Button>
            </Link>
          </ImageHighlightCard>
        </Section>
        <Section size="2">
          <ImageHighlightCard
            node={<Img fluid={begrippenImage.childImageSharp.fluid} />}
            orientation="ltr"
          >
            <Heading
              appearance="secondary"
              component="h1"
              size="xl"
              weight="bold"
            >
              {prop('conceptsTitle', copy)}
            </Heading>
            <RichText>{prop('conceptsBody', copy)}</RichText>
            <br />
            <Link to="/encyclopedie/begrippen">
              <Button styling="solid">
                <span>{prop('conceptsButtonLabel', copy)}</span>
                <Icon type="arrowCircleRight" size="lg" />
              </Button>
            </Link>
          </ImageHighlightCard>
        </Section>
      </MainWrapper>
    </Layout>
  );
};

export default Encyclopedia;
